import { useState } from 'react';
import {
  Text,
  Box,
  DataTable,
  ColumnConfig,
  TextInput,
  CheckBox,
} from 'grommet';
import { Search } from 'grommet-icons';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Wine } from '../../types/wine';
import { MODAL_KEY } from '../modal/modal-key.enum';
import { openModal } from '../modal/modal.slice';
import { setSelectedWine } from './wine.slice';
import { getWines } from '../../AppSlice';
import { getIsUserAdmin } from '../../AppSlice';

export function WineTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [shouldShowOnlyCurrentWines, setShouldShowOnlyCurrentWines] =
    useState(true);
  const isUserAdmin = useAppSelector(getIsUserAdmin);
  const wines = useAppSelector(getWines);
  const dispatch = useAppDispatch();

  if (!wines) return null;

  function onClickWine(event: { datum: Wine }) {
    const wine = event.datum;
    dispatch(setSelectedWine(wine));
    dispatch(openModal(MODAL_KEY.NOTES_MODAL));
  }

  const filteredWines = wines
    .map((wine: Wine) => ({
      ...wine,
      price: isUserAdmin ? wine?.price : undefined,
    }))
    .filter((wine) =>
      Object.values(wine).some((value) => {
        if (shouldShowOnlyCurrentWines && wine.num_bottles === 0) return false;
        return value
          ?.toString()
          .toLowerCase()
          ?.includes(searchTerm.toLowerCase());
      })
    );

  const columns: ColumnConfig<Wine>[] = [
    {
      property: 'year',
      header: <Text>Year</Text>,
      sortable: true,
    },
    {
      property: 'winery',
      header: <Text>Winery</Text>,
      sortable: true,
    },
    {
      property: 'name',
      header: <Text>Name</Text>,
    },
    {
      property: 'type',
      header: <Text>Type</Text>,
      sortable: true,
    },
    {
      property: 'varietal',
      header: <Text>Varietal</Text>,
      sortable: true,
    },
    {
      property: 'num_bottles',
      header: <Text>Bottles Remaining</Text>,
      sortable: true,
    },
  ];

  if (isUserAdmin) {
    columns.splice(5, 0, {
      property: 'price',
      header: <Text>Price</Text>,
      sortable: true,
      render: (datum) => <Text>${datum.price}</Text>,
    });
  }

  return (
    <Box
      margin={{ top: 'medium' }}
      animation={{ type: 'fadeIn', duration: 750 }}
    >
      <Box direction="row" flex>
        <Box direction="column" flex={{ grow: 0.25 }}>
          <TextInput
            name="search"
            id="search-input"
            placeholder="Cabernet"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={<Search />}
            autoFocus
          />
        </Box>
      </Box>
      <Box margin={{ top: 'medium' }}>
        <CheckBox
          checked={shouldShowOnlyCurrentWines}
          label="Show only current wine"
          onChange={(event) =>
            setShouldShowOnlyCurrentWines(event.target.checked)
          }
        />
      </Box>
      <DataTable
        columns={columns}
        data={filteredWines}
        onSort={() => 'asc'}
        onClickRow={onClickWine}
        margin={{ top: 'medium' }}
      />
    </Box>
  );
}
