import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './app/store';
import { Wine } from './types/wine';

export interface AppState {
  isUserAdmin: boolean;
  wines?: Wine[];
}

const initialState: AppState = {
  isUserAdmin: false,
  wines: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsUserAdmin: (state, action: PayloadAction<boolean>) => {
      state.isUserAdmin = action.payload;
    },
    setWines: (state, action: PayloadAction<Wine[] | undefined>) => {
      state.wines = action.payload;
    },
  },
});

export const { setIsUserAdmin, setWines } = appSlice.actions;

export const getIsUserAdmin = (state: RootState) => state.app.isUserAdmin;
export const getWines = (state: RootState) => state.app.wines;

export default appSlice.reducer;
