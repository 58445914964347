import { useState } from 'react';
import { Text, FormField, Box, TextInput, Button, Form, Layer } from 'grommet';
import { Secure, Close } from 'grommet-icons';

import { closeModal } from '../modal/modal.slice';
import { supabase } from '../../supabase-client';
import { useAppDispatch } from '../../app/hooks';
import { setIsUserAdmin } from '../../AppSlice';

export function SignInModal() {
  const [errorText, setErrorText] = useState<string>('');

  const dispatch = useAppDispatch();

  function close() {
    dispatch(closeModal());
  }

  async function onSubmit({
    value,
  }: {
    value: { email: string; password: string };
  }) {
    setErrorText('');

    const { error } = await supabase.auth.signIn({
      email: value.email,
      password: value.password,
    });

    if (error) {
      setErrorText(error.message);
      return;
    }

    dispatch(setIsUserAdmin(true));
    dispatch(closeModal());
  }

  return (
    <Layer onEsc={close} onClickOutside={close}>
      <Box width="large" pad="large">
        <Box alignSelf="end" onClick={close}>
          <Close size="medium" />
        </Box>
        <Box direction="row" margin="auto">
          <Secure size="xlarge" color="brand" />
        </Box>
        <Text margin={{ top: 'medium' }}>
          Enter your email address and password for admin access.
        </Text>
        <Form onSubmit={onSubmit}>
          <FormField
            label="Email address"
            margin={{ top: 'medium' }}
            name="email"
            htmlFor="email-input"
            validate={{
              regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            }}
            required
          >
            <TextInput
              name="email"
              id="email-input"
              placeholder="admin@ken.wine"
              autoFocus
            />
          </FormField>
          <FormField
            label="Password"
            margin={{ top: 'medium' }}
            name="password"
            htmlFor="password-input"
            required
          >
            <TextInput
              type="password"
              name="password"
              id="password-input"
              placeholder="********"
            />
          </FormField>
          {errorText && (
            <Box margin={{ top: 'medium' }}>
              <Text color="status-error">{errorText}</Text>
            </Box>
          )}
          <Box direction="row" justify="end">
            <div id="recaptcha-container">
              <Button
                primary
                type="submit"
                size="large"
                label="Submit"
                margin={{ top: 'large' }}
                name="submitButton"
                id="submit-button"
              />
            </div>
          </Box>
        </Form>
      </Box>
    </Layer>
  );
}
