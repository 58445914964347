import { Text, Box, Button, Layer } from 'grommet';
import { Notes, Close } from 'grommet-icons';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { getIsUserAdmin } from '../../AppSlice';
import { clearSelectedWine, getSelectedWine } from '../wine-table/wine.slice';
import { MODAL_KEY } from '../modal/modal-key.enum';
import { closeModal, openModal } from '../modal/modal.slice';

export function NotesModal() {
  const isUserAdmin = useAppSelector(getIsUserAdmin);
  const wine = useAppSelector(getSelectedWine);
  const dispatch = useAppDispatch();

  if (!wine) return null;

  function onClickEdit() {
    dispatch(openModal(MODAL_KEY.ADD_WINE_MODAL));
  }

  function close() {
    dispatch(closeModal());
    dispatch(clearSelectedWine());
  }

  return (
    <Layer onEsc={close} onClickOutside={close}>
      <Box width="large" pad="large">
        <Box alignSelf="end" onClick={close}>
          <Close size="medium" />
        </Box>
        <Box direction="row" margin="auto">
          <Notes size="large" color="brand" />
        </Box>
        <Text margin={{ top: 'medium' }} alignSelf="center">
          {wine.year} {wine.winery} {wine.name}
        </Text>
        <Text margin={{ top: 'medium' }} style={{ whiteSpace: 'pre-line' }}>
          {wine.notes || 'No notes about this wine.'}
        </Text>
        {isUserAdmin && (
          <Box alignSelf="end" margin={{ top: 'medium' }}>
            <Button label="Edit wine" primary onClick={onClickEdit} />
          </Box>
        )}
      </Box>
    </Layer>
  );
}
