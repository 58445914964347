import { Box, Text } from 'grommet';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useAppSelector } from '../../app/hooks';
import { getWines } from '../../AppSlice';
import { Wine } from '../../types/wine';

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function WineChart({ property }: { property: keyof Wine }) {
  const wines = useAppSelector(getWines);

  if (!wines) return null;

  const chartLabel = capitalize(property);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const map: Record<string, number> = {};
  for (const wine of wines) {
    const value = wine[property];
    if (!value) continue;
    if (!map[value]) {
      map[value] = 1;
      continue;
    }
    map[value] = map[value] + 1;
  }

  const labels = Object.keys(map);
  const data = Object.values(map);

  const chartData = {
    labels,
    datasets: [
      {
        label: chartLabel,
        data,
        backgroundColor: [
          'rgba(255, 0, 0, 0.25)',
          'rgba(0, 0, 139, 0.25)',
          'rgba(0, 100, 0, 0.25)',
          'rgba(100, 0, 100, 0.25)',
          'rgba(0, 255, 0, 0.25)',
          'rgba(255, 255, 0, 0.25)',
          'rgba(100, 149, 237, 0.25)',
          'rgba(176, 48, 96, 0.25)',
          'rgba(0, 255, 255, 0.25)',
          'rgba(255, 222, 173, 0.25)',
        ],
        borderColor: [
          'rgba(255, 0, 0)',
          'rgba(0, 0, 139)',
          'rgba(0, 100, 0)',
          'rgba(100, 0, 100)',
          'rgba(0, 255, 0)',
          'rgba(255, 255, 0)',
          'rgba(100, 149, 237)',
          'rgba(176, 48, 96)',
          'rgba(0, 255, 255)',
          'rgba(255, 222, 173)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box
      width={{ min: '350px', max: '500px' }}
      flex={{ grow: 0, shrink: 0 }}
      margin={{ top: 'medium' }}
      basis="1/3"
    >
      <Text alignSelf="center">{chartLabel}</Text>
      <Doughnut data={chartData} />
    </Box>
  );
}
