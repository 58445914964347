import { useAppSelector } from '../../app/hooks';
import { getModalKey } from './modal.slice';

import { MODAL_CONTENT_MAP } from './modal-map';

export function Modal() {
  const modalKey = useAppSelector(getModalKey);
  if (!modalKey) return null;

  const ModalContent = MODAL_CONTENT_MAP[modalKey];
  if (!ModalContent) return null;

  return <ModalContent />;
}
