import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { MODAL_KEY } from '../modal/modal-key.enum';

export interface ModalState {
  modalKey?: MODAL_KEY;
}

const initialState: ModalState = {
  modalKey: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalKey = undefined;
    },
    openModal: (state, action: PayloadAction<MODAL_KEY | undefined>) => {
      state.modalKey = action.payload;
    },
  },
});

export const { openModal } = modalSlice.actions;
export const { closeModal } = modalSlice.actions;

export const getModalKey = (state: RootState) => state.modal.modalKey;

export default modalSlice.reducer;
