import { useState } from 'react';
import {
  Text,
  FormField,
  Box,
  TextInput,
  Button,
  Form,
  Layer,
  TextArea,
} from 'grommet';
import { Add, Edit, Close } from 'grommet-icons';

import { Wine } from '../../types/wine';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeModal } from '../modal/modal.slice';
import { supabase } from '../../supabase-client';
import {
  clearSelectedWine,
  getSelectedWine,
  setShouldFetchWine,
} from '../wine-table/wine.slice';

export function AddWineModal() {
  const wineToEdit = useAppSelector(getSelectedWine);

  const [errorText, setErrorText] = useState<string>('');
  const [year, setYear] = useState<string>(wineToEdit?.year || '');
  const [winery, setWinery] = useState<string>(wineToEdit?.winery || '');
  const [name, setName] = useState<string>(wineToEdit?.name || '');
  const [type, setType] = useState<string>(wineToEdit?.type || '');
  const [varietal, setVarietal] = useState<string>(wineToEdit?.varietal || '');
  const [price, setPrice] = useState<string>(
    wineToEdit?.price?.toString() || ''
  );
  const [num_bottles, setNumBottles] = useState<number>(
    wineToEdit?.num_bottles || 1
  );
  const [notes, setNotes] = useState<string>(wineToEdit?.notes || '');

  const dispatch = useAppDispatch();

  function close() {
    if (wineToEdit) {
      dispatch(clearSelectedWine());
    }
    dispatch(closeModal());
  }

  async function onSubmit({ value: wine }: { value: Partial<Wine> }) {
    setErrorText('');

    if (wineToEdit) {
      const { error } = await supabase
        .from('wine')
        .update({
          ...wine,
        })
        .match({ id: wineToEdit.id });

      if (error) {
        setErrorText(error.message);
        return;
      }
    } else {
      const { error } = await supabase.from('wine').insert({
        ...wine,
        num_bottles,
      });

      if (error) {
        setErrorText(error.message);
        return;
      }
    }

    dispatch(setShouldFetchWine(true));
    close();
  }

  return (
    <Layer onEsc={close} onClickOutside={close} margin="medium">
      <Box width="large" pad="large" overflow={{ vertical: 'scroll' }}>
        <Box alignSelf="end" onClick={close}>
          <Close size="medium" />
        </Box>
        <Box direction="row" margin="auto">
          {wineToEdit ? (
            <Edit size="large" color="brand" />
          ) : (
            <Add size="large" color="brand" />
          )}
        </Box>
        <Box height={{ min: 'auto' }} margin={{ top: 'large' }}>
          <Form onSubmit={onSubmit}>
            {/* Year */}
            <FormField
              label="Year"
              margin={{ top: 'medium' }}
              name="year"
              htmlFor="year-input"
              required
            >
              <TextInput
                name="year"
                id="year-input"
                placeholder="2013"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                autoFocus
              />
            </FormField>
            {/* Winery */}
            <FormField
              label="Winery"
              margin={{ top: 'medium' }}
              name="winery"
              htmlFor="winery-input"
              required
            >
              <TextInput
                name="winery"
                id="winery-input"
                placeholder="Ridge Vineyards"
                value={winery}
                onChange={(e) => setWinery(e.target.value)}
              />
            </FormField>
            {/* Name */}
            <FormField
              label="Name"
              margin={{ top: 'medium' }}
              name="name"
              htmlFor="name-input"
              required
            >
              <TextInput
                name="name"
                id="name-input"
                placeholder="Monte Bello"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormField>
            {/* Type */}
            <FormField
              label="Type"
              margin={{ top: 'medium' }}
              name="type"
              htmlFor="type-input"
              required
            >
              <TextInput
                name="type"
                id="type-input"
                placeholder="Red"
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
            </FormField>
            {/* Varietal */}
            <FormField
              label="Varietal"
              margin={{ top: 'medium' }}
              name="varietal"
              htmlFor="varietal-input"
              required
            >
              <TextInput
                name="varietal"
                id="varietal-input"
                placeholder="Cabernet Sauvignon"
                value={varietal}
                onChange={(e) => setVarietal(e.target.value)}
              />
            </FormField>
            {/* Price */}
            <FormField
              label="Price"
              margin={{ top: 'medium' }}
              name="price"
              htmlFor="price-input"
              required
            >
              <TextInput
                name="price"
                id="price-input"
                placeholder="450"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormField>
            {/* Bottles Remaining */}
            {wineToEdit && (
              <FormField
                label="Bottles Remaining"
                margin={{ top: 'medium' }}
                name="num_bottles"
                htmlFor="num_bottles-input"
                required
              >
                <TextInput
                  name="num_bottles"
                  id="num_bottles-input"
                  placeholder="2"
                  value={num_bottles}
                  onChange={(e) => setNumBottles(parseInt(e.target.value) || 0)}
                  type="number"
                />
              </FormField>
            )}
            {/* Notes */}
            <FormField
              label="Notes"
              margin={{ top: 'medium' }}
              name="notes"
              htmlFor="notes-input"
            >
              <TextArea
                name="notes"
                id="notes-input"
                placeholder="80% Cabernet Sauvignon, 8% Petit Verdot, 7% Cabernet Franc, 5% Merlot"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormField>
            {errorText && (
              <Box margin={{ top: 'medium' }}>
                <Text color="status-error">{errorText}</Text>
              </Box>
            )}
            <Box direction="row" justify="end">
              <Button
                primary
                type="submit"
                size="large"
                label="Submit"
                margin={{ top: 'large' }}
                name="submitButton"
                id="submit-button"
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Layer>
  );
}
