import { MODAL_KEY } from './modal-key.enum';
import { AddWineModal } from '../add-wine-modal/add-wine-modal';
import { SignInModal } from '../sign-in-modal/sign-in-modal';
import { NotesModal } from '../notes-modal/notes-modal';

export const MODAL_CONTENT_MAP: Record<MODAL_KEY, () => JSX.Element | null> = {
  [MODAL_KEY.ADD_WINE_MODAL]: AddWineModal,
  [MODAL_KEY.SIGN_IN_MODAL]: SignInModal,
  [MODAL_KEY.NOTES_MODAL]: NotesModal,
};
