import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { Wine } from '../../types/wine';

export interface WineState {
  selectedWine?: Wine;
  shouldFetchWine: boolean;
}

const initialState: WineState = {
  selectedWine: undefined,
  shouldFetchWine: true,
};

export const wineSlice = createSlice({
  name: 'wine',
  initialState,
  reducers: {
    setSelectedWine: (state, action: PayloadAction<Wine | undefined>) => {
      state.selectedWine = action.payload;
    },
    clearSelectedWine: (state) => {
      state.selectedWine = undefined;
    },
    setShouldFetchWine: (state, action: PayloadAction<boolean>) => {
      state.shouldFetchWine = action.payload;
    },
  },
});

export const { setSelectedWine, clearSelectedWine, setShouldFetchWine } =
  wineSlice.actions;

export const getSelectedWine = (state: RootState) => state.wine.selectedWine;
export const getShouldFetchWine = (state: RootState) =>
  state.wine.shouldFetchWine;

export default wineSlice.reducer;
