import { useEffect, useState } from 'react';
import {
  Grommet,
  Main,
  Button,
  Page,
  PageContent,
  PageHeader,
  Box,
  Tabs,
  Tab,
} from 'grommet';

import { supabase } from './supabase-client';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setIsUserAdmin, getIsUserAdmin, setWines } from './AppSlice';
import { openModal } from './features/modal/modal.slice';
import { MODAL_KEY } from './features/modal/modal-key.enum';
import { WineTable } from './features/wine-table/wine-table';
import { Modal } from './features/modal/modal';
import {
  getShouldFetchWine,
  setShouldFetchWine,
} from './features/wine-table/wine.slice';
import { WineChart } from './features/wine-chart/wine-chart';

import { theme } from './grommet-theme';
import './App.css';

function App(): JSX.Element {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const isUserAdmin = useAppSelector(getIsUserAdmin);
  const shouldFetchWine = useAppSelector(getShouldFetchWine);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsWaiting(true);
    // Wait until the client session info would be available before trying to get it
    setTimeout(() => {
      const session = supabase.auth.session();
      if (session) dispatch(setIsUserAdmin(true));
      setIsWaiting(false);
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    async function fetchWines() {
      const { data: wines, error } = await supabase.from('wine').select();
      if (error) {
        console.error(error.message);
        return;
      }
      wines.sort(
        (a, b) =>
          (parseInt(a.year) || Infinity) - (parseInt(b.year) || Infinity)
      );
      dispatch(setWines(wines));
      dispatch(setShouldFetchWine(false));
    }
    if (shouldFetchWine) fetchWines();
  }, [dispatch, shouldFetchWine]);

  function onClickSignIn() {
    dispatch(openModal(MODAL_KEY.SIGN_IN_MODAL));
  }

  function onClickAddWine() {
    dispatch(openModal(MODAL_KEY.ADD_WINE_MODAL));
  }

  let actionButton;
  if (isUserAdmin)
    actionButton = <Button label="Add Wine" primary onClick={onClickAddWine} />;
  else
    actionButton = (
      <Button label="Admin Sign-in" primary onClick={onClickSignIn} />
    );

  return (
    <Grommet theme={theme} className="app">
      <img
        src="./vine-header.webp"
        alt="vines"
        width="100%"
        style={{ position: 'absolute', zIndex: -1 }}
      />
      <Main pad="large">
        <Page>
          <PageContent>
            <PageHeader
              title="Ken's Wine"
              subtitle="Here's all the wine in my collection. The table is sortable by column and you can click a wine to see details. Enjoy!"
              actions={
                isWaiting ? undefined : (
                  <Box
                    animation={{ type: 'fadeIn', duration: 750 }}
                    direction="row"
                    flex
                  >
                    <Box alignSelf="center">{actionButton}</Box>
                  </Box>
                )
              }
              responsive
            />
            <Tabs alignControls="start">
              <Tab title="Table">
                <WineTable />
              </Tab>
              <Tab title="Charts">
                <Box
                  direction="row"
                  justify="center"
                  wrap
                  animation={{ type: 'fadeIn', duration: 750 }}
                >
                  <WineChart property="type" />
                  <WineChart property="varietal" />
                  <WineChart property="winery" />
                </Box>
              </Tab>
            </Tabs>
          </PageContent>
        </Page>
        <Modal />
      </Main>
    </Grommet>
  );
}

export default App;
